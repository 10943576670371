<template>
  <v-sheet  class="fill-height d-flex align-center justify-center"
            :style="elementStyle"
            :color="!element.config.outlined ? GET_AVALON_COLOR(element.config.background_color) : 'transparent'"
  >
  </v-sheet>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "elementButtonEditor",
  props : {
    value : {
      type : Object,
      default() { return {} }
    },
    block : {}
  },
  data() {
    return {
      element : { config : {}},
    }
  },
  computed : {
    elementStyle() {
      let style = ''

      if (this.element.config.outlined) {
        style += `border : ${this.element.config.outline_width || 1}px solid ${this.GET_AVALON_COLOR(this.element.config.background_color)} !important;`
      }
      if (this.element.config.rounded) {
        style += `border-radius : ${this.element.config.rounded}px;`
      }

      style += this.GET_CLIPPING_MASK(this.element.config.mask || null , this.element.config.mask_resize)

      return style
    }
  },
  watch : {
    value : {
      handler() {
        if ( JSON.parse(JSON.stringify(this.value)) !== JSON.parse(JSON.stringify(this.element)) ) {
          this.element = JSON.parse(JSON.stringify(this.value))
        }
      },
      deep : true
    }
  },
  methods : {
    ...mapActions('avalonPage', ['EDIT_ELEMENT']),
  },
  mounted(){
    if ( this.value ) {
      this.element = JSON.parse(JSON.stringify(this.value))
      this.$emit('input',this.element)
    }
  }
}
</script>

<style scoped>

</style>